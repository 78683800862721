import React from 'react';
import Layout from '../../../components/layout';
import { AltWrapper, Title, TileWrapper } from '../../../styles/commonStyles';
import Plane from '../../../assets/svgs/plane.svg';
import styled from 'styled-components';

const StyledPlane = styled(Plane)`
  max-width: 180px;
  margin: auto;
`;

export type EmailSentProps = {
  location: {
    state: {
      email: string;
    };
  };
};

const EmailSent = ({ location: { state } }: EmailSentProps) => {
  const email = (state && state.email) || '';

  return (
    <Layout>
      <AltWrapper>
        <div>
          <TileWrapper>
            <Title>We sent an email to {email}</Title>
            <StyledPlane />
          </TileWrapper>
          <p>
            If this is the email that you registered with Bó then you should
            receive an email containing a login link. Please note the link will
            expire in 15 minutes.
          </p>
        </div>
      </AltWrapper>
    </Layout>
  );
};

export default EmailSent;
